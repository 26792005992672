<template>
  <div class="MaintenanceComponent d-flex flex-column w-100 h-100">
    <div class="d-flex flex-column MaintenanceComponent__top justify-content-end">
      <img
        :src="EmptyState"
        data-test-id="empty_state-image"
        class="MaintenanceComponent__top__illustration_wrapper"
        alt="Page under maintenance"
      >
    </div>
    <div class="MaintenanceComponent__bottom d-flex flex-column text-center">
      <h4 class="MaintenanceComponent__bottom__title emobg-font-weight-bold justify-content-center d-flex mb-2">
        {{ $t('Maintenance.title') }}
      </h4>
      <div class="MaintenanceComponent__bottom__description d-flex emobg-font-medium justify-content-center align-self-center mt-2">
        {{ $t('Maintenance.description') }}
      </div>
    </div>
  </div>
</template>
<script>
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { emptyCarpoolingTrips as emptyState } from '@/utils/publicImages';

export default {
  name: 'MaintenanceComponent',

  created() {
    this.setLoaderStatus(false);
    this.EmptyState = emptyState;
  },

  methods: {
    setLoaderStatus,
  },
};
</script>
<style lang="scss" scoped>
.MaintenanceComponent {
  &__top {
    height: 55%;
    background: no-repeat url("../../../../public/images/generic/curve.svg");

    @media screen {
      background-size: 100% 80%;
    }

    &__empty_space {
      flex-grow: 20;
    }

    &__illustration_wrapper {
      height: 80%;
      margin-top: auto;
    }
  }

  &__bottom {
    height: 45%;
    padding-top: 5vh;

    @media screen {
      padding: 0 24px;
    }

    @media screen and (min-width: 768px) {
      padding: 0 48px;
    }

    &__title {
      padding-top: 5vh;
    }

    &__description {
      max-width: 896px;
    }

  }
}
</style>
